<template>
	<v-app theme="DashboardTheme">
		<v-snackbar-queue v-model="snackbarStore.messages" location="right top" closable close-text="Luk" color="#111827" timeout="5000" timer eager/>
		<v-navigation-drawer v-model="dashboardStore.drawer" :permanent="$vuetify.display.smAndUp ? true : false">
			<v-list-item prepend-avatar="/img/logo.png" to="/" lines="two">
				<v-list-item-title>Forside</v-list-item-title>
			</v-list-item>
			<v-list-item :prepend-icon="mdiViewDashboard" to="/panel" lines="two">
				<v-list-item-title>Panel</v-list-item-title>
			</v-list-item>
			<v-list-item>
				<v-divider />
			</v-list-item>
			<v-list-item>
				<v-list-item-title class="text-center">Servere</v-list-item-title>
			</v-list-item>
			<v-list-item lines="two">
				<template #prepend>
					<NuxtLink v-if="dashboardStore.selectService !== null" :to="'/panel/services/' + dashboardStore.selectService?._id">
						<v-icon :icon="mdiServer" />
					</NuxtLink>
					<v-icon v-else :icon="mdiServer" />
				</template>
				<v-list-item-action>
					<v-select
						v-model="selectedService"
						:items="(dashboardStore.services as Service[]).map((service) => ({ title: service.nicename, id: service._id }))"
						item-text="name"
						item-value="id"
						variant="solo"
						dense
						@update:menu="updateSelectedService"
					/>
				</v-list-item-action>
			</v-list-item>
			<div v-if="dashboardStore.selectService?.type === 'playerserver' || dashboardStore.selectService === null">
				<v-list-item v-for="item in items" :key="item.title" :prepend-icon="item.icon" :to="item.to.replace(':id', dashboardStore.selectService?._id as string)" :disabled="dashboardStore.selectService === null" lines="two">
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item>
			</div>
			<div v-else>
				<v-list-item :prepend-icon="mdiConsole" :to="'/panel/services/' + dashboardStore.selectService?._id + '/console'" :disabled="dashboardStore.selectService === null" lines="two">
					<v-list-item-title>Console</v-list-item-title>
				</v-list-item>
			</div>
		</v-navigation-drawer>
		<v-app-bar app>
			<v-app-bar-nav-icon @click="dashboardStore.toggleDrawer()" />
			<v-toolbar-title>
				<v-breadcrumbs :items="dashboardStore.title">
					<template #divider>
						<v-icon :icon="mdiForward"/>
					</template>
				</v-breadcrumbs>
			</v-toolbar-title>
			<v-spacer />
			<v-tooltip location="bottom" text="Klik her for at gå til link siden.">
				<template #activator="{ props }">
					<v-btn v-bind="props" icon @click="navigateTo('/link')">
						<v-icon :icon="mdiAccountPlus"/>
					</v-btn>
				</template>
			</v-tooltip>
			<v-tooltip location="bottom" text="Klik her for at logge ud.">
				<template #activator="{ props }">
					<v-btn v-bind="props" icon @click="navigateTo('/logout')">
						<v-icon :icon="mdiLogout"/>
					</v-btn>
				</template>
			</v-tooltip>
		</v-app-bar>
		<v-main>
			<NuxtLoadingIndicator />
			<NuxtPage />
		</v-main>
	</v-app>
</template>

<script setup lang="ts">
import { mdiAccountPlus, mdiChartLine, mdiCog, mdiConsole, mdiFolder, mdiForward, mdiLogout, mdiServer, mdiViewDashboard, mdiWan } from '@mdi/js';
import { useAuthStore } from '~~/stores/auth';
import { useDashboardStore, type Service } from '~~/stores/dashboard';
import { useSnackBarStore } from '~~/stores/snackbar';

const selectedService = ref<string | null>(null);
const dashboardStore = useDashboardStore();
if (dashboardStore.selectService === null) {
	selectedService.value = null;
} else {
	selectedService.value = dashboardStore.selectService._id;
}
const route = useRoute();
const router = useRouter();
const snackbarStore = useSnackBarStore();
const authStore = useAuthStore();
if (!await authStore.checkAuth()) {
	navigateTo('/login');
}
await dashboardStore.getServices();
onMounted(() => {
	dashboardStore.initInterval();
	console.log('Dashboard mounted');
});

router.afterEach(async (path) => {
	console.log('Dashboard afterEach');
	if (path.params.id) {
		let id: string | null = path.params.id as string;
		if (id.toLowerCase() === 'primary') {
			id = dashboardStore.services.find((service) => service.info.primary)?._id || null;
		}

		if (id === null || id === undefined) {
			snackbarStore.addMessage({
				text: 'Du har ingen primær server!',
				color: 'error',
			});
			navigateTo('/panel');
		} else {
			if (dashboardStore.services.find((service) => service._id === id) === undefined) {
				snackbarStore.addMessage({
					text: 'Serveren findes ikke!',
					color: 'error',
				});
				navigateTo('/panel');
			}
			selectedService.value = id;
			dashboardStore.setSelectService(id);
			if (path.fullPath.includes('primary')) {
				navigateTo(path.fullPath.replace('primary', id));
			}
		}
	}
});

if (route.params.id) {
	let id: string | null = route.params.id as string;
	if (id.toLowerCase() === 'primary') {
		id = dashboardStore.services.find((service) => service.info.primary)?._id || null;
	}

	if (id === null || id === undefined) {
		snackbarStore.addMessage({
			text: 'Du har ingen primær server!',
			color: 'error',
		});
		navigateTo('/panel');
	} else {
		if (dashboardStore.services.find((service) => service._id === id) === undefined) {
			snackbarStore.addMessage({
				text: 'Serveren findes ikke!',
				color: 'error',
			});
			navigateTo('/panel');
		}
		selectedService.value = id;
		dashboardStore.setSelectService(id);
		if (route.fullPath.includes('primary')) {
			navigateTo(route.fullPath.replace('primary', id));
		}
	}
}

async function updateSelectedService() {
	if (selectedService.value !== null) {
		dashboardStore.setSelectService(selectedService.value);
		navigateTo(`/panel/services/${selectedService.value}`);
	}
}

const items = ref([
	{
		title: "Activities",
		icon: mdiViewDashboard,
		to: "/panel/services/:id/activities",
		disabled: false,
	},
	{
		title: "Backups",
		icon: mdiFolder,
		to: "/panel/services/:id/backups",
		disabled: true,
	},
	{
		title: "Console",
		icon: mdiConsole,
		to: "/panel/services/:id/console",
		disabled: false,
	},
	{
		title: "Filemanager",
		icon: mdiFolder,
		to: "/panel/services/:id/filemanager",
		disabled: true,
	},
	{
		title: "Indstillinger",
		icon: mdiCog,
		to: "/panel/services/:id/settings",
		disabled: false,
	},
	{
		title: "Plugins",
		icon: mdiFolder,
		to: "/panel/services/:id/plugins",
		disabled: true,
	},
	{
		title: "Ports",
		icon: mdiWan,
		to: "/panel/services/:id/ports",
		disabled: true,
	},
	{
		title: "Stats",
		icon: mdiChartLine,
		to: "/panel/services/:id/stats",
		disabled: true,
	},
].filter((item) => item.disabled === false));

useHead({
	titleTemplate: (title) => (title ? `${title} - MineClub` : 'MineClub'),
	link: [
		{
			rel: 'apple-touch-icon',
			sizes: '180x180',
			href: '/apple-touch-icon.png',
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '32x32',
			href: '/favicon-32x32.png',
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '16x16',
			href: '/favicon-16x16.png',
		},
		{
			rel: 'manifest',
			href: '/site.webmanifest',
		},
	],
	meta: [
		{
			hid: 'description',
			name: 'description',
			content: 'MineClub - En dansk Minecraft server!',
		},
		{
			hid: 'og:title',
			property: 'og:title',
			content: 'MineClub - En dansk Minecraft server!',
		},
		{
			hid: 'og:description',
			property: 'og:description',
			content: 'MineClub - En dansk Minecraft server!',
		},
		{
			hid: 'og:type',
			property: 'og:type',
			content: 'website',
		},
		{
			hid: 'og:image',
			property: 'og:image',
			content: '/og-image.png',
		},
	],
	htmlAttrs: {
		lang: 'da-DK',
	},
});
</script>

<style scoped>
a {
	color: inherit;
	text-decoration: none;
}
</style>